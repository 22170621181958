import React from "react";
import { Container } from 'react-bootstrap';
import { Section } from 'react-scroll-section';
import { RowSpace } from '../components/Row';
import Contact from '../components/Contact';

const ContactSection = () => (

    <Section id="contact" style={{ backgroundColor: '#E5E1D6' }}>
        <RowSpace />
      <Container className="mx-auto">
            <Contact />
            <RowSpace />
      </Container>
    </Section>
);

export default ContactSection;