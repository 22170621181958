import React from 'react';
import { Row, Col, Card as BSCard, CardImg, CardTitle, CardText } from 'reactstrap';
import './styles.css';

export function Card({ keyId, imgUrl, title, text, theme }) {
    
    return (
        <BSCard key={keyId} body className={theme == 'secondary' ? 'pzt-card-secondary' : null}>
            <div className="pzt-card-title">
                <CardImg top width="100%" src={imgUrl} />
                <CardTitle className="pzt-title-card" >{title}</CardTitle>
            </div>
            <CardText className="pzt-card-text">{text}</CardText>
        </BSCard>
    );
}

function RowBuilder(cards, keyId) {
    return (
        <Row  key={keyId}  className="row-group-card">
            {ColumnBuilder(cards)}
        </Row>
    );
}

function ColumnBuilder(cards) {
    if (cards.length == 0) {
        return [];
    }

    var result = 12 / cards.length;
    var columCount = Math.floor(result);
    let columns = [];

    for (let index = 0; index < cards.length; index++) {
        const element = cards[index];
        columns.push(<Col key={index.toString()} md={columCount}>{element}</Col>);
    }

    return columns;
}

export function CardGroup({ children, itemsPerRow }) {
    var count = 0;
    let rows = [];
    let childrens = React.Children.toArray(children);
    itemsPerRow = itemsPerRow > 12 ? 12 : itemsPerRow;
    
    while (childrens.length > 0) {
        let _childrens = [];
        for (let index = 0; index < itemsPerRow; index++) {
            _childrens.push(childrens[index]);
        }

        count++;

        var row = RowBuilder(_childrens, count);
        rows.push(row);

        childrens.splice(0, itemsPerRow);
    }

    return (rows);
};

