import React, { useState } from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler,
   NavItem, NavLink, Collapse, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useScrollSection } from 'react-scroll-section';
import logo from '../../assets/images/logo-brand.png';
import './styles.css';


function Header(params) {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const homeSection = useScrollSection('home');
  const aboutSection = useScrollSection('about');
  const feedSection = useScrollSection('feed');
  const contactSection = useScrollSection('contact')

  const textLink1 = "Nossas obras";
  const textLink2 = "Quem somos";
  const textLink3 = "Fazer orçamento";

  return (

    <Navbar fixed={'top'} className="border-bottom box-shadow" color="light" light expand="md">
    <Container className="mx-auto">

        <NavbarBrand tag={Link} to="/"  onClick={homeSection.onClick} selected={homeSection.selected}>
          <img className="Logo" src={logo} /></NavbarBrand>
        <NavbarToggler onClick={toggle} className="mr-2" />

        <Collapse className="d-md-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
          <Nav navbar>

            <NavItem>
              <NavLink tag={Link} to="/"  className="text-dark" onClick={feedSection.onClick} 
                selected={feedSection.selected}>{textLink1}</NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/"  className="text-dark" onClick={aboutSection.onClick} 
                selected={aboutSection.selected}>
                  {textLink2}
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink tag={Link} to="/"  className="text-dark" onClick={contactSection.onClick} 
                selected={contactSection.selected}>{textLink3}</NavLink>
            </NavItem>

          </Nav>
        </Collapse>

    </Container>
    </Navbar>
  );

}

export default Header;
