import React from "react";
import { Section } from 'react-scroll-section';
import { ThemeProvider } from 'styled-components';
import { Card, CardGroup } from '../components/Card';
import { Title, Subtitle } from '../components/Text';
import Banner from '../components/Banner';
import { Container } from 'react-bootstrap'

import theme from '../theme/theme';
import comercial from '../assets/images/icon-comercial.png';
import obras from '../assets/images/icon-obras.png';
import residencial from '../assets/images/icon-residencial.png';

const HomeSection = () => (

    <Section id="home">
        <Banner />
        <br /><br />
        <ThemeProvider theme={theme}>
            <Title variant="primary">{'Cuidamos de todas as etapas da sua reforma'}</Title>
            <Subtitle>{'Veja como a Parizatto pode ajudar você.'}</Subtitle>
        </ThemeProvider>
        <br /><br />
        <Container className="mx-auto">
            <CardGroup keyId={1} itemsPerRow={3}>
                <Card keyId={1}
                    imgUrl={obras}
                    title={'Execução de obras'}
                    text={`Estudamos tudo sobra a sua obra antes de começar. 
                    Você já inicia sabendo quanto vai gastar e quando vai acabar.`}
                />
                <Card keyId={2}
                    imgUrl={residencial}
                    title={'Reforma residencial'}
                    text={`Tomamos todo o cuidado com a limpeza e com am execução, 
                    podemos decorar seus ambientes e deixar  tudo personalizado.`}
                />
                <Card keyId={3}
                    imgUrl={comercial}
                    title={'Reforma comercial'}
                    text={`Seu projeto é levado a sério. Um arquiteto responsável cuidará
                    de cada detalhe para que não aconteça nenhuma surpresa.`}
                />
            </CardGroup>
        </Container>
    </Section>
);

export default HomeSection;