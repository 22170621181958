import React from "react";
import { ScrollingProvider } from 'react-scroll-section';
import Header from '../components/Header';
import HomeSection from "../sections/HomeSection";
import AboutSection from "../sections/AboutSection";
import FeedSection from "../sections/FeedSection";
import ContactSection from "../sections/ContactSection";
import Footer from "../components/Footer";

function Home() {

    return (
        <div>
            <ScrollingProvider>
                <Header />
                <HomeSection />
                <AboutSection />
                <FeedSection />
                <ContactSection />
                <Footer />
            </ScrollingProvider>
        </div>
    );
}

export default Home;