import React from "react";
import { Col, Row, Container } from "reactstrap";
import logo from '../../assets/images/logo-footer.png';
import instagram from '../../assets/images/footer-instagram.png';
import facebook from '../../assets/images/footer-facebook.png';
import whatsapp from '../../assets/images/icon-whatsapp.png';
import mail from '../../assets/images/icon-mail.png';
import './styles.css';

const url = 'https://www.instagram.com/parizatto.arquitetura/';

function Footer(params) {
    return (
        <div className="footer-pzt">
            <Container className="mx-auto">
                <Row>
                    <Col sm="6" className="footer-pzt-logo">
                        <img src={logo} alt=""></img>
                    </Col>
                    <Col sm="6" className="footer-pzt-label">
                        <div>{'Siga a Parizatto:'}</div>
                        <a href={url}><img src={instagram} /></a><span></span>
                        <a href='#'><img src={facebook} /></a>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6" style={{ marginTop: '20px', marginBottom: '50px' }}>
                        <div className='footer-pzt-about1'>
                            {'A Parizatto é uma empresa focada na execução de reformas e construções'}
                        </div>
                        <div className='footer-pzt-about2'>
                            {
                                `Especializada em atender Arquitetos, Designers e clientes finais. 
                                 Sempre com total transparência, comprometimento, limpeza e pontualidade em sua gestão.`
                            }
                        </div>
                    </Col>
                    <Col sm="6" className='footer-pzt-br'>

                        <div className="footer-pzt-phone">
                            <img src={whatsapp} alt="" /><span></span>
                            {'+55 (11) 9 7551-4001'}
                        </div>

                        <div className="footer-pzt-mail">
                            <img src={mail} alt="" /><span></span>
                            {'contato@parizatto.com.br'}
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;