import styled from 'styled-components';

export const ContentAreaContainer = styled.section`
  margin-left: 17%;
  margin-right: 17%;
  height: 100%;
  display: flex;
  align-items: left;
  justify-content: left;
  position: relative;
  z-index: 10;
  @media (max-width: 800px) {
    padding-top: 100px;
    flex-direction: column;
  }
`;

ContentAreaContainer.Item = styled.div`
  width: 50%;
  display: inline-block;
  margin-bottom: 50px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

ContentAreaContainer.Category = styled.h1`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: center;
  display: inline-block; 
  padding: 25px;
  line-height: 1;
  border-radius: 4px;

  @media (max-width: 800px) {
    display: none;
    font-size: 18px;
    padding: 10px;
  }
`;

ContentAreaContainer.Description = styled.p`
    font-family: 'Fira Sans';
    color: #FFFF;
    max-width: 350px;
    margin-bottom: 25px;
    @media (max-width: 576px) {
      display: none;
  }
`;

ContentAreaContainer.Title = styled.h2`
  font-family: 'Zilla Slab';
  font-style: normal;
  color: #FFFF;
  font-weight: 300;
  font-size: 40px;
  line-height: 1.33333;
  margin-top: 0px;
  margin-bottom: 20px;
 
  @media (max-width: 800px) {
    font-size: 32px;
    text-align: justify;
  }
`;

export const BannerMainContainer = styled.section`
  height: 60vh;
  position: relative;
  color: #fff;
  margin-bottom: 4%;
  background-color: #3f4352;
  /* background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};  */
  background-size: cover;
  background-position: center;
  @media (max-width: 576px) {
    height: auto;
    min-height: 20vh;
  }

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    height: 20%;
  }

  /* &:before {
    top: 0;
    height: 100%;
    background: rgba(0,0,0,0.5);
  } */

  /* &:after {
    bottom: 0;
    background: linear-gradient(0deg, #141414 0%, transparent 100%);
  } */
`;


