import colorGrade from "./colors/colors"

const defaultPallet = {
    primary: {
        color: colorGrade.ocre[0],
        hoverColor: colorGrade.ocre[1],
        contrastText: colorGrade.white[0],
    },
    secondary: {
        color: colorGrade.blue[0],
        hoverColor: colorGrade.blue[1],
        contrastText: colorGrade.white[0],
    },
    defaul: {
        color: colorGrade.white[0],
    }
}

const foundationTokens = {
    colors: defaultPallet,
    commons: {
        transition: '.3s ease-in-out',
        buttonBorderRadius: '0',
        fontSize: '14px',
    },
    spacing: {
        1: '4px',
        2: '6px',
        3: '8px',
        4: '16px',
        5: '24px',
        6: '32px',
    }
}

export default foundationTokens;