import styled from 'styled-components';

export const Title =  styled.h4`
    color: ${({ theme, variant }) => theme.colors[variant].color};
    text-align: center;
    font-family: 'Zilla Slab';
    font-style: normal;
    font-weight: 600;
`;

export const Subtitle =  styled.h6`
    color: #616161;
    text-align: center;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
`;

export const FormTitle = styled.div`
    font-size: 25px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 50px;
    color: #FFF;
`;

export const FormSubTitle = styled.div`
    font-size: 20px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 100;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 50px;
    margin-bottom: 25px;
    color: #FFF;
`;

export const ModalTitle = styled.div`
    font-size: 21px;
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 50px;
    color: #FFF;
`;