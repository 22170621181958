import React from "react";
import { Jumbotron, Container } from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { ContentAreaContainer } from './styles';
import { Button, ButtonOutline } from '../Buttons';
import { useScrollSection } from 'react-scroll-section';
import theme from '../../theme/theme';
import banner from '../../assets/images/icon-corporativo.jpg';

import './styles.css';


function Banner() {

    const aboutSection = useScrollSection('about');
    const contactSection = useScrollSection('contact')

    return (

        <div>
            <Jumbotron fluid className="banner-pzt" style={{ backgroundImage: `url(${banner})`}}>

                <Container className="mx-auto" >
                    <ContentAreaContainer.Title>
                        {"Arquitetura"}
                        <br />
                        {"Reforma"}
                        <br />
                        {"Construção"}
                    </ContentAreaContainer.Title>

                    <ContentAreaContainer.Description>
                        {"Da execução da obra até os últimos retoques, faça sua reforma de maneira mais fácil."}
                    </ContentAreaContainer.Description>

                    <ThemeProvider theme={theme}>
                        <Button 
                            variant="primary" 
                            onClick={contactSection.onClick}
                            selected={contactSection.selected}>
                            {'Fazer orçamento'}
                        </Button>&emsp;

                        <ButtonOutline 
                            variant="primary" 
                            onClick={aboutSection.onClick}
                            selected={aboutSection.selected}>
                                {'Quem somos'}
                        </ButtonOutline>
                    </ThemeProvider>

                </Container>

            </Jumbotron>

        </div>
    );
}

export default Banner;