import React, { useState } from 'react';
import { css } from "@emotion/core";
import { Form, FormGroup, Row, Input, Col, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { ThemeProvider } from 'styled-components';
import { ButtonBlock } from '../../components/Buttons';
import { Subtitle, FormTitle, FormSubTitle, ModalTitle } from '../../components/Text';
import MaskedInput from "react-text-mask";
import PulseLoader from 'react-spinners/PulseLoader'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailRepository from '../../repositories/email';
import theme from '../../theme/theme';
import './styles.css';

function Contact() {
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const phoneNumberMask = ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
    const phoneNumberMatch = /^[(]{0,1}[0-9]{1,4}[)]{0,1}[" "]{0,1}[-\s\./0-9]*$/g;
    const toggle = () => setModal(!modal);

    const initialValues = {
        name: '',
        email: '',
        phone: '',
        period: ''
    };

    const FormSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, 'O nome deve ter no mínimo 3 caractéres')
            .required('Preencha o nome'),
        email: Yup.string()
            .email('Formato de email incorreto')
            .required('Preencha o email'),
        phone: Yup.string().matches(phoneNumberMatch, 'número inválido')
            .required('Informe o celular'),
        period: Yup.string()
            .required('Informe o período de retorno')
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return 'is-valid';
        }

        return '';
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: FormSchema,
        onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
            enableLoading();
            setTimeout(async () => {
                await emailRepository.send(values.name, values.email, values.phone, values.period)
                    .then(() => {
                        disableLoading();
                        setSubmitting(true);
                        resetForm(initialValues);
                        toggle();
                    })
                    .catch(() => {
                        disableLoading();
                        setSubmitting(false);
                        setStatus('Deu Ruim');
                    });
            }, 1000);
        },
    });

    const override = css`
        display: block;
        `;

    return (

        <ThemeProvider theme={theme}>

            <Row form>
                <Col sm='12' md={{ size: 6, offset: 3 }} className='contact-header'>
                    <FormTitle>{'Agende uma reunião agora mesmo.'}</FormTitle>
                    <FormSubTitle>{'Preencha o formulário, entraremos em contato.'}</FormSubTitle>
                </Col>

                <Col sm='12' md={{ size: 6, offset: 3 }} className='contact-form'>
                    {loading ? (<div className='text-center'>
                        <PulseLoader css={override} color={'#2F3150'} />
                        <br></br>
                    </div>) : null}

                    <Form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <Input
                                name='name'
                                type='text'
                                autoComplete='false'
                                placeholder='Insira seu nome'
                                className={getInputClasses('name')}
                                {...formik.getFieldProps('name')}
                            />
                            {formik.errors.name && formik.touched ?
                                (<div className='alert-text'>{formik.errors.name}</div>) : null}
                        </FormGroup>

                        <FormGroup>
                            <Input
                                name='email'
                                type='email'
                                autoComplete='false'
                                placeholder='E-mail'
                                className={getInputClasses('email')}
                                {...formik.getFieldProps('email')}
                            />
                            {formik.errors.email && formik.touched ?
                                (<div className='alert-text'>{formik.errors.email}</div>) : null}
                        </FormGroup>

                        <FormGroup>
                            <MaskedInput
                                className={`form-control ${getInputClasses('phone')}`}
                                name='phone'
                                type='text'
                                autoComplete='false'
                                placeholder='Celular'
                                mask={phoneNumberMask}
                                {...formik.getFieldProps('phone')}
                            />
                            {formik.errors.phone && formik.touched ?
                                (<div className='alert-text'>{formik.errors.phone}</div>) : null}
                        </FormGroup>

                        <FormGroup>
                            <Label for="peridoRetorno" className="contact-label">Qual melhor período para entrarmos em contato?</Label>
                            <Input 
                                type="select" 
                                name="periodo" 
                                id="peridoRetorno" 
                                autoComplete='false'
                                className={getInputClasses('period')}
                                {...formik.getFieldProps('period')}
                                >
                                <option value="">Selecione</option>
                                <option value="Manhã (das 8h às 12h)">Manhã (das 8h às 12h)</option>
                                <option value="Tarde (das 12h às 17h)">Tarde (das 12h às 17h)</option>
                                <option value="Noite (das 17h às 20h)">Noite (das 17h às 20h)</option>
                            </Input>
                            {formik.errors.period && formik.touched ?
                                (<div className='alert-text'>{formik.errors.period}</div>) : null}
                        </FormGroup>

                        <ButtonBlock
                            variant='secondary'
                            type='submit'
                            disabled={formik.isSubmitting}>
                            <span>{'Agendar uma reunião'}</span>
                        </ButtonBlock>
                    </Form>
                </Col>

            </Row>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} style={{ backgroundColor: '#2F3150' }}>
                    <ModalTitle>
                        {'Agendamento de Reunião'}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className='text-center'>
                    <Subtitle variant='secondary'>{'Solicitação enviada com sucesso!'}</Subtitle>
                    <Subtitle variant='secondary'>{'Em breve entraremos em contato.'}</Subtitle>
                </ModalBody>
                <ModalFooter>
                    <ButtonBlock
                        variant='secondary'
                        onClick={toggle}>
                        <span>{'Sair'}</span>
                    </ButtonBlock>
                </ModalFooter>
            </Modal>

        </ThemeProvider>
    );
}

export default Contact;