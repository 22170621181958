import styled from 'styled-components';

export const Button = styled.button`
    background-color : ${({ theme, variant }) => theme.colors[variant].color};
    color: ${({ theme, variant }) => theme.colors[variant].contrastText};
    border-color: ${({ theme, variant }) => theme.colors[variant].color} !important;
    transition: ${({ theme }) => theme.commons.transitions};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[6]}`};
    font-size: ${({ theme }) => theme.commons.fontSize};
    margin-right: 5px;
    border: 1px solid;
    min-width: 172px;

    &:hover {
        background-color:  ${({ theme, variant }) => theme.colors[variant].hoverColor};
        border-color: ${({ theme, variant }) => theme.colors[variant].hoverColor};
    }
`;

export const ButtonOutline = styled.button`
    background-color : transparent;
    color: ${({ theme, variant }) => theme.colors[variant].contrastText};
    border-color: ${({ theme, variant }) => theme.colors[variant].color} !important;
    transition: ${({ theme }) => theme.commons.transitions};
    border-radius: ${({ theme }) => theme.commons.buttonBorderRadius};
    padding: ${({ theme }) => `${theme.spacing[2]} ${theme.spacing[6]}`};
    font-size: ${({ theme }) => theme.commons.fontSize};
    border: 1px solid;
    min-width: 172px;

    &:hover{
        background-color:  ${({ theme, variant }) => theme.colors[variant].color};
    }

    @media (max-width: 800px) {
        text-align: center;
        margin-top: 4%;
    }
`;

export const ButtonBlock = styled.button`
    background-color : ${({ theme, variant }) => theme.colors[variant].color};
    color: ${({ theme, variant }) => theme.colors[variant].contrastText};
    border-color: ${({ theme, variant }) => theme.colors[variant].color} !important;
    transition: ${({ theme }) => theme.commons.transitions};
    padding: ${({ theme }) => `${theme.spacing[3]} ${theme.spacing[6]}`};
    font-size: ${({ theme }) => theme.commons.fontSize};
    margin-right: 5px;
    border: 1px solid;
    display: block;
    width: 100%;
    &:hover {
        background-color:  ${({ theme, variant }) => theme.colors[variant].hoverColor};
        border-color: ${({ theme, variant }) => theme.colors[variant].hoverColor};
    }
`;