import React from "react";
import { ThemeProvider } from 'styled-components';
import { Jumbotron, Container } from 'react-bootstrap';
import { Card, CardGroup } from '../components/Card';
import { Title, Subtitle } from '../components/Text';
import { Section } from 'react-scroll-section';
import { RowSpace } from '../components/Row';

import about from '../assets/images/banner-about.png';
import compromisso from '../assets/images/icon-compromisso.png';
import consultoria from '../assets/images/icon-consultoria.png';
import decoracao from '../assets/images/icon-decoracao.png';
import reducao from '../assets/images/icon-reducao.png';
import theme from '../theme/theme';

const AboutSection = () => (

    <Section id="about">
        <RowSpace />
        <Container className="mx-auto">
            <Jumbotron className="pzt-mini-banner" style={{
                backgroundImage: `url(${about})`,
                backgroundSize: 'cover'
            }} >
                <p>
                    {`"Vamos transformar em realidade todos os projetos 
                confiados a nós, conte com a Parizatto para transformar sonhos em realidade."`}
                </p>
            </Jumbotron>

            <br /><br />
            <ThemeProvider theme={theme}>
                <Title variant="secondary">{'Atendemos todas as suas necessidades'}</Title>
                <Subtitle>{'Veja como a Parizatto pode ajudar você.'}</Subtitle>
            </ThemeProvider>
            <br /><br />


            <CardGroup keyId={2} itemsPerRow={2}>
                <Card keyId={1}
                    imgUrl={consultoria}
                    theme={'secondary'}
                    title={'Consultoria exclusiva'}
                    text={`Um arquiteto prestará toda consultoria, acompanhando e 
                explicando cada detalhe, tirando dúvidas e adaptando o orçamento aos objetivos do projeto.`}
                />
                <Card keyId={2}
                    imgUrl={decoracao}
                    theme={'secondary'}
                    title={'Decoração personalizada'}
                    text={`Personalizamos seu projeto para trazer o melhor resultado estético e prático
                com itens decorativos como quadros e vasos, além de jogo de cama, entre outros.`}
                />
                <Card keyId={3}
                    imgUrl={compromisso}
                    theme={'secondary'}
                    title={'Compromisso com a entrega'}
                    text={`Os serviços serão executados conforme o planejamento, garantindo todos os prazos
                Nosso compromisso é entregar cada etapa na data combinada.`}
                />

                <Card keyId={4}
                    imgUrl={reducao}
                    theme={'secondary'}
                    title={'Redução de custos'}
                    text={`Ao estudar o projeto e ajustar todas as etapas garantimos
                que os materiais serão comprados na quantidade certa e damaneira certa reduzindo seus custos.`}
                />
            </CardGroup>
        </Container>
    </Section>
);

export default AboutSection;