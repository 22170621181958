import axios from 'axios';

async function get(profile, count) {
    const res = await axios.get(`/api/feed/${profile}/${count}`);
    if (res.status == 200) {
        let response = res.data;
        return response;
    }
    
    throw new Error('Não foi possível pegar os dados');
}

export default {
    get,
};