import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import feedRepository from "../../repositories/feed";
import "./styles.css";
import insta1 from "../../assets/images/insta1.jpeg";
import insta2 from "../../assets/images/insta2.jpeg";
import insta3 from "../../assets/images/insta3.jpeg";

const url = "https://www.instagram.com/parizatto.arquitetura/"

function Feed({ profile, count }) {
//   const [data, setData] = useState([]);

//   useEffect(() => {
//     async function fetchData() {
//       await feedRepository
//         .get(profile, count)
//         .then((feed) => {
//           setData(feed);
//         })
//         .catch((err) => {
//           console.log(err.message);
//         });
//     }
//     fetchData();
//   }, []);

  return (
    <>
      <Row>
        <Col key={"pzt1"} sm="4" className="feed-col">
          <a href={url}>
            <img alt={"insta1"} src={insta1}></img>
          </a>
        </Col>
        <Col key={"pzt2"} sm="4" className="feed-col">
          <a href={url}>
            <img alt={"insta2"} src={insta2}></img>
          </a>
        </Col>
        <Col key={"pzt3"} sm="4" className="feed-col">
          <a href={url}>
            <img alt={"insta3"} src={insta3}></img>
          </a>
        </Col>
      </Row>
    </>
  );
}

export default Feed;
