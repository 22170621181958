import React from "react";
import {Container} from 'react-bootstrap';
import { ThemeProvider } from 'styled-components';
import { Title, Subtitle } from '../components/Text';
import { Button } from '../components/Buttons';
import { Section } from 'react-scroll-section';
import { RowSpace } from '../components/Row';
import Feed from "../components/Feed";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from "@fortawesome/free-brands-svg-icons";

import theme from '../theme/theme';
const url = 'https://www.instagram.com/parizatto.arquitetura/';

const FeedSection = () => (
    
    <Section id="feed">
        <br/><br/>
        <ThemeProvider theme={theme}>
            <Title variant="secondary">{'Acompanhe nossas obras'}</Title>
            <Subtitle>{'Siga-nos no Instagram, temos posts frequentes dos trabalhos realizados'}</Subtitle>
        </ThemeProvider>

        <Container className="mx-auto">
            <Feed profile={'parizatto.arquitetura'} count={3} />
        </Container>

        <div className="col text-center" style={{ marginBottom: '60px'}}>
            <ThemeProvider theme={theme}>
                <Button variant="secondary" className='button-instagram'
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.href = url;
                    }}>
                    <span>
                        {'Siga a Parizatto'}
                    </span>
                    <FontAwesomeIcon icon={Icons.faInstagram} size="2x" pull="right" />
                </Button>
            </ThemeProvider>
        </div>
        {/* <RowSpace /> */}
    </Section>

);

export default FeedSection;